<template>
  <div class="titlesTop">
    <headTitle title="活动招募" />
    <mescroll-vue
      class="voluntaryRecruitment gundong"
      ref="mescroll"
      :down="mescrollDown"
      :up="mescrollUp"
      @init="mescrollInit"
    >
      <van-search
        v-model="activity_name"
        show-action
        shape="round"
        background="#efefef"
        placeholder="请输入搜索关键词"
        @search="onSearch"
        @clear="onClear"
      >
        <template #action>
          <div @click="onSearch">搜索</div>
        </template>
      </van-search>
      <div
        class="volunteerBlock"
        v-for="(items, index) in dataList"
        :key="index"
        @click="goDetil(items)"
      >
        <div class="activeTitle">
          <span>{{items.name}}</span>
          <!-- <span v-if="items.isSignUp" :class="statusBack(items.recruitStatus)">已报名</span>
          <span v-else :class="statusBack(items.recruitStatus)" >{{items.recruitStatus | filterStatus}}</span>-->
        </div>
        <p class="activeContent">{{items.introduction}}</p>
        <ul class="activeDetail">
          <li>{{items.activityLocation}}</li>
          <li>活动开始时间：{{items.activityBeginTime}}</li>
          <li>
            <span>招募人数：{{items.recruitPeople}}</span>
          </li>
        </ul>
      </div>
      <div id="empty"></div>
    </mescroll-vue>
  </div>
</template>

<script>
// 引入mescroll的vue组件
import MescrollVue from 'mescroll.js/mescroll.vue'
import Recruitapi from '@/api/recruit'

export default {
  data() {
    return {
      mescroll: null, // mescroll实例对象
      mescrollDown: {}, //下拉刷新的配置. (如果下拉刷新和上拉加载处理的逻辑是一样的,则mescrollDown可不用写了)
      mescrollUp: { // 上拉加载的配置.
        callback: this.upCallback, // 上拉回调,此处简写; 相当于 callback: function(page, mescroll) { }
        //以下是一些常用的配置,当然不写也可以的.
        page: {
          num: 0, //当前页 默认0,回调之前会加1; 即callback(page)会从1开始
          size: 10 //每页数据条数,默认10
        },
        empty: {
          //列表第一页无任何数据时,显示的空提示布局; 需配置warpId才显示
          warpId: "empty", //父布局的id (1.3.5版本支持传入dom元素)
          icon: require('../../assets/mescroll/mescroll-empty.png'), // 图标,支持网络图
          tip: "暂无数据" //提示
        }
      },
      params: {
        page: 1,
        sise: 10
      },
      activity_name: '',  // 搜索框输入的活动名
      dataList: [],       // 
      value1: 0,
      option1: [
        { text: '未开始', value: 0 },
        { text: '已开始', value: 1 },
        { text: '已结束', value: 2 },
      ]
    }
  },
  components: {
    MescrollVue
  },
  filters: {
    filterStatus(val) {
      if (val === '0') {
        return '开始招募'
      } else if (val === '1') {
        return '招募中'
      } else if (val === '2') {
        return '停止招募'
      }
    }
  },
  methods: {
    // 过滤函数，用于在 开始招聘 招聘中 停止招聘 显示不同的背景颜色 
    // 传入当前招聘状态，0 表示开始招聘，1表示招聘中，2表示停止招聘， 返回定义好的className
    statusBack(val) {
      if (val === '0') {
        return 'startBg'
      } else if (val === '1') {
        return 'beingBg'
      } else if (val === '2') {
        return 'stopBg'
      }
    },
    // 跳转进入志愿者招募详情中
    goDetil(val) {
      if (this.isToken()) { // 没用登入调到登入
        var link = window.location.href.split("#/")[0] + "#/voluntaryRecruitmentDetail?id=" + val.id
        window.location.href = link
      }
    },
    mescrollInit(mescroll) { //滚动对象
      this.mescroll = mescroll;
    },
    upCallback(page, mescroll) {
      console.log(page);
      this.params.page = page.num;
      this.params.size = page.size;
      this.params.appName = this.appName;
      Recruitapi.getRecruitList(this.params, this.activity_name).then((res) => {
        let arr = res.data.items;
        if (arr) {
          if (page.num === 1) {
            this.dataList = [];
          }
          this.dataList = this.dataList.concat(arr);
          console.log(this.dataList);
          this.$nextTick(() => {
            mescroll.endSuccess(arr.length);
          })
        } else {
          if (page.num === 1) {
            this.dataList = [];
          }
          this.$nextTick(() => {
            mescroll.endSuccess(0);
          })
        }
      }).catch((err) => {
        mescroll.endErr();
      })
    },
    onSearch() {
      var page = {
        num: 1,
        size: 10,
        time: null
      }
      this.upCallback(page, this.mescroll);
      // Recruitapi.getRecruitList(this.params,this.activity_name).then((res) => {
      //     this.dataList = res.data.items;
      // }).catch((err) => {
      //     console.log(err);
      // })
    },
    // 点击清除按钮
    onClear() {
      this.onSearch();
    }
  }
}
</script>
<style>
.voluntaryRecruitment .mescroll-empty {
  margin-top: 20vh;
}
</style>
<style lang="less" scoped>
.voluntaryRecruitment {
  width: 100%;
  height: 100%;
  padding: 0 10px;
  background-color: #efefef;
  overflow-y: auto;
  box-sizing: border-box;
  .volunteerBlock {
    background-color: #fff;
    margin: 10px 0;
    padding: 10px;
    border-radius: 5px;
    .activeTitle {
      display: flex;
      justify-content: space-between;
      align-items: center;
      // font-weight: 600;
      .startBg {
        background-color: #07c160;
      }
      .beingBg {
        background-color: #00a0e9;
      }
      .stopBg {
        background-color: #696969;
        font-size: 0.28rem;
      }
      span {
        &:nth-child(1) {
          width: 75%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-size: 0.36rem;
        }
        &:nth-child(2) {
          width: 20%;
          text-align: center;
          padding: 3px;
          color: #fff;
          border-radius: 3px;
        }
      }
    }
    .activeContent {
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      text-overflow: ellipsis;
      -webkit-box-orient: vertical;
      margin: 0.16rem 0;
      color: rgb(94, 94, 94);
      font-size: 0.32rem;
      line-height: 0.5rem;
    }
    .activeDetail {
      font-size: 3.5vw;
      color: rgb(94, 94, 94);
      padding: 10px;
      li {
        margin: 4px 0;
        line-height: 0.4rem;
        i {
          font-size: 3.5vw;
        }
      }
    }
  }
}
/*通过fixed固定mescroll的高度*/
// .mescroll {
//   position: fixed;
//   top: 0px;
//   bottom: 0;
//   height: auto;
// }
</style>
